<template>
    <v-content>
        <v-breadcrumbs :items="breadCrumb" large></v-breadcrumbs>
        <v-snackbar :timeout="callbackResponse.timeout" :color="callbackResponse.color" top v-model="snackbar">
            {{callbackResponse.message}}
            <v-btn @click="snackbar = false" dark text>Close</v-btn>
        </v-snackbar>

        <v-dialog max-width="500px" v-model="dialogDelete">
            <deleteListData :deleteFormData="deleteFormData" @formResponse="onResponse"></deleteListData>
        </v-dialog>
        <br />
        <div>
            <v-select
                      class="mt-8"
                      :items="customYear"
                      dense
                      outlined
                      v-on:change="getChangedList(yearFromDate)"
                      :label="$t('select_year')"
                      v-model="yearFromDate"
                      prepend-inner-icon="event"></v-select>
            <v-data-table :headers="columns"
                          :items="rows"
                          :options.sync="options"
                          :server-items-length="rows.length"
                          :loading="loading"
                          class="elevation-1"
                          hide-default-header
                          hide-default-footer>

                <template v-slot:header="props">
                    <thead>
                        <tr class="text-center">
                            <th rowspan="2" class="text-center">{{$t('s_no')}} </th>
                            <th rowspan="2">{{$t('peak')}}</th>
                            <th rowspan="2">{{$t('permitted_team')}}</th>
                            <th colspan="2">{{$t('permitted_member')}}</th>
                            <th rowspan="2">{{$t('total_permitted_member')}}</th>
                            <th rowspan="2">{{$t('royalty_us')}}</th>
                            <th rowspan="2">{{$t('actions')}}</th>

                        </tr>
                        <tr class="text-center">
                            <th rowspan="2">{{$t('male')}}</th>
                            <th rowspan="2">{{$t('female')}}</th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:item="props">
                    <tr class="text-center">
                        <td class="text-center border ">
                            <div> {{props.item.countIndex}}</div>
                        </td>
                        <td>
                            <v-text-field dense
                                          v-if="props.item.isEdit || props.item.isCreate"
                                          v-model="props.item.peak"
                                          class="shrink"></v-text-field>
                            <div v-else> {{props.item.peak}}</div>
                        </td>
                        <td>
                            <v-text-field dense
                                          v-model="props.item.permittedTeam"
                                          type="number"
                                          v-if="props.item.isEdit || props.item.isCreate"
                                          class="shrink"></v-text-field>
                            <div v-else> {{props.item.permittedTeam}}</div>

                        </td>

                        <td>
                            <v-text-field dense
                                          v-model="props.item.male"
                                          type="number"
                                          v-if="props.item.isEdit || props.item.isCreate"
                                          class="shrink"></v-text-field>
                            <div v-else> {{props.item.male}}</div>

                        </td>
                        <td>
                            <v-text-field dense
                                          v-model="props.item.female"
                                          type="number"
                                          v-if="props.item.isEdit || props.item.isCreate"
                                          class="shrink"></v-text-field>
                            <div v-else> {{props.item.female}}</div>

                        </td>
                        <td>
                            <v-text-field dense
                                          v-model="props.item.totalPermittedMember"
                                          type="number"
                                          v-if="props.item.isEdit || props.item.isCreate"
                                          class="shrink"></v-text-field>
                            <div v-else> {{props.item.totalPermittedMember}}</div>

                        </td>
                        <td>
                            <v-text-field dense
                                          v-model="props.item.royalty"
                                          type="number"
                                          v-if="props.item.isEdit || props.item.isCreate"
                                          class="shrink"></v-text-field>
                            <div v-else> {{props.item.royalty}}</div>

                        </td>
                        <td>
                            <v-icon class="mr-2" v-if="props.item.isCreate" @click="props.item.isCreate=false;submit(props.item)">save</v-icon>
                            <v-icon class="mr-2" v-if="props.item.isEdit" @click="props.item.isEdit=false;submitEdit(props.item)">save</v-icon>
                            <v-icon @click="props.item.isEdit=true;props.item.isCreate=false;" class="mr-2">edit</v-icon>
                            <v-icon v-if="!props.item.isCreate" @click="deleteItem(props.item)">delete</v-icon>
                            <v-icon v-if="props.item.isCreate" @click="createRemoveArray(props.index)">delete</v-icon>
                            <v-icon v-if="rows.length == 0 || rows.length-1 === props.index" @click="addTableData">add</v-icon>

                        </td>

                    </tr>

                </template>
                <template slot="body.append">
                    <tr style="background-color: #808080b8;text-align:center">
                        <td colspan="2">Total</td>
                        <td>{{totals.totalPermittedTeam}}</td>
                        <td>{{totals.totalMale}}</td>
                        <td>{{totals.totalFemale}}</td>
                        <td>{{totals.totalPermittedMemberSum}}</td>
                        <td>{{totals.totalRoyalty}}</td>
                        <td></td>
                    </tr>
                </template>

            </v-data-table>
        </div>

    </v-content>
</template>

<script>
    import axios from "axios";
    //import sectorForm from "./create";
    //import editSectorForm from "./edit";
    import deleteListData from "@/components/deleteModal";

    export default {
        name: "BuyingRate",
        components: {
            //sectorForm,
            //editSectorForm,
            deleteListData
        },
        computed: {
            breadCrumb() {
                return [
                    {
                        text: this.$t('dashboard'),
                        disabled: false,
                        to: '/NepalMountaineeringAssociationDashboard',
                        exact: true

                    },
                    {
                        text: this.$t('mwcarr'),
                        disabled: true,
                    },
                ]
            },
            customYear() {
                let year = []
                for (let i = 0; i < 99; i++) {
                    year.push(`20${i < 10 ? '0' : ''}${i}`)
                }
                return year
            },
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000,
                    color:''
                },
                isCreate:false,
                snackbar: false,
                success: false,
                dialogCreate: false,
                dialogEdit: false,
                dialogDelete: false,
                deleteFormData: {},
                popout: true,
                loading:false,
                isLoading: false,
                columns: [
                    {
                        label: "Fiscal Year",
                        field: "fiscalYear"
                    },
                    {
                        label: "NRs. (Million)",
                        field: "nrs"
                    },
                    {
                        label: "Exchange Rate",
                        field: "exchangeRate"
                    },
                    {
                        label: "US$ (Million)",
                        field: "amountUsd"
                    },
                    {
                        label: "%Change in US$",
                        field: "change"
                    },
                    {
                        label: "Actions",
                        field: "actions",
                        sortable: false
                    }
                ],
                rows: [],
                totals: [],
                totalRecords: 0,
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "fiscalYear",
                            type: "asc"
                        }
                    ],
                    page: 1,
                    perPage: 100
                },
                user: [],
                editedIndex: -1,
                editedData: {
                    serviceID: "",
                    serviceName: "",
                    serviceDescription: ""
                },
                yearFromDate: "",
            };
        },
        mounted() {
            this.loadItems();
            this.customYear();
           
        },
        created() { this.currentYear(); },
        methods: {
            async submit(data) {
                    let param = {
                        peak: data.peak.toString(),
                        permittedTeam: parseInt(data.permittedTeam),
                        male: parseInt(data.male),
                        female: parseInt(data.female),
                        totalPermittedMember: parseInt(data.totalPermittedMember),
                        royalty: parseFloat(data.royalty),
                        year: this.yearFromDate + '-01-01',
                    }
                    await axios.post("Mountaining/InsertIntoNMAAsync", param).then(response => {
                        if (response.data.success) {
                            this.snackbar = true
                            this.callbackResponse.data = response.data
                            this.callbackResponse.message = "Royalty Data added Succcessfully"
                            this.callbackResponse.color = "success"

                        }

                    }).catch(response => {
                        this.snackbar = true
                        this.callbackResponse.data = response.data
                        this.callbackResponse.message = "Error Adding Royalty Data, Please Contact Admin"
                        this.callbackResponse.color = "error"
                    });
                this.loadItems();

            },
           async submitEdit(data) {
                    let param = {
                        peak: data.peak.toString(),
                        permittedTeam: parseInt(data.permittedTeam),
                        male: parseInt(data.male),
                        female: parseInt(data.female),
                        totalPermittedMember: parseInt(data.totalPermittedMember),
                        royalty: parseFloat(data.royalty),
                        year: this.yearFromDate + '-01-01',
                        mountainID: data.mountainID
                    }
                   await axios.post("Mountaining/UpdateNMAAsync", param).then(response => {
                            this.callbackResponse.data = response.data
                            this.snackbar = true
                            this.callbackResponse.message = "Data updated Successfully"
                            this.callbackResponse.color = "success"

                    }).catch(response => {
                        this.snackbar = true
                        this.callbackResponse.data = response.data
                        this.callbackResponse.message = "Error Updating Data, Please Contact Admin"
                        this.callbackResponse.color = "error"
                    });
                this.loadItems();

            },
            getChangedList() {
                this.loadItems();
            },
            createRemoveArray(index) {
                this.rows.splice(index,1)
            },
            addTableData() {
                this.rows.push({
                    fiscalYear: '',
                    nrs: '',
                    exchangeRate: '',
                    amountUsd: '',
                    change: '',
                    isEdit: false,
                    isCreate: true,
                })
            },
            onResponse(data) {
                if (data.message) {
                    (this.dialogDelete = false), (this.dialogCreate = false);
                    this.dialogEdit = false;
                    this.callbackResponse = data;
                    this.snackbar = true
                    this.loadItems();
                } else {
                    this.dialogCreate = false;
                    this.dialogEdit = false;
                    this.dialogDelete = false
                }
            },
            editItem(props) {
                this.editedData.mountainID = props.mountainID;
                this.dialogEdit = true;
            },
            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Mountaining/DeleteNMAAsync/" + props.mountainID;
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = {}
                    this.editedIndex = -1;
                }, 300);
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            searchInput() {
                this.loadItems();
            },
            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field
                        }
                    ]
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            // load items is what brings back the rows from server
            async loadItems() {
                //console.log(this.tokenData);
                // eslint-disable-next-line no-console
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    Year: this.yearFromDate + '-01-01',
                };
                axios.post("Mountaining/GetNMAListAsync", param)
                    .then(({data}) => {
                        this.rows = data.listvalue;
                        this.totals = data.total;
                        this.rows.map(res => {
                            this.$set(res, 'isEdit', false);
                            this.$set(res, 'isCreate', false);
                            return res;
                        })
                        this.totalRecords = data.totalCount;
                        if (this.rows.length == 0) {
                            this.addTableData();
                            this.totalRecords = 1
                        }

                    })
                    .catch(err => {
                        console.log(err)
                    });
            },
            currentYear() {
                const newdate = new Date().getFullYear().toString();
                this.yearFromDate = newdate; 
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-item-group {
        background: #d2e3ff73;
    }

    .v-expansion-panel-header {
        background: #d2e3ff73;
    }

    button {
        z-index: 1;
    }
    ::v-deep .v-text-field, ::v-deep .v-text-field--solo {
        width: 100px !important;
    }
    ::v-deep .v-data-table__wrapper {
        width: 100% !important;
    }
    .border {
        border: 1px solid black !important;
    }
    table,td {
        height: 40px !important;
        border: 1px solid black !important;
        border-collapse: collapse;
    }
    thead {
        background-color: #1976D2;
        color: white;

        th {
            height: 40px !important;
            border: 1px solid !important;
            color: white !important;
            text-align: center !important;
            border-color: white;
            font-size: 15px !important;
        }
    }
    ::v-deep .v-text-field {
        width: 160px !important;
    }
</style>
